import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"

export default function FrameModule({ content, headline, image }) {
  const img = getImage(image.localFile)

  return (
    <EdgesContainer size="md">
      <Container>
        <StyledImage image={img} alt={image.altText || ""} />
        <ContentContainer size="lg">
          <h2>{headline}</h2>
          <div className="max-w-[700px]">
            <p>{content}</p>
          </div>
        </ContentContainer>
      </Container>
    </EdgesContainer>
  )
}

const EdgesContainer = styled(Edges)`
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  height: 100%;
`

const Container = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5%;
`

const StyledImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  img {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media (min-width: 768px) {
    height: 75%;
    padding: 50px;
  }
`

const Headline = styled(Typography)`
  margin-bottom: 20px;
  max-width: 700px;
  text-transform: capitalize;
`

const Content = styled(Typography)`
  max-width: 700px;
  color: #727272;
`
