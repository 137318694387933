import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"
import HTMLReactParser from "html-react-parser"

export default function HeroSection({ headline, subline, images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [currentImageIndex, images.length])

  return (
    <Container>
      <ContentContainer size="md">
        {headline && (
          <Headline variant="h2" gutterBottom>
            {HTMLReactParser(headline)}
          </Headline>
        )}
        {subline && <Subline>{subline}</Subline>}
      </ContentContainer>
      <ButtonContainer>
        {images.map((image, index) =>
          index === currentImageIndex ? (
            <ButtonStyle isVisible={true} key={index} onClick={() => setCurrentImageIndex(index)} />
          ) : (
            <ButtonStyle isVisible={false} key={index} onClick={() => setCurrentImageIndex(index)} />
          )
        )}
      </ButtonContainer>
      {images.map((item, index) => {
        const carouselImage = item?.image && getImage(item?.image.localFile)

        return index === currentImageIndex ? (
          <Image isVisible={true} key={index}>
            <GatsbyImage image={carouselImage} alt={item?.image.altText} />
          </Image>
        ) : (
          <Image isVisible={false} key={index}>
            <GatsbyImage image={carouselImage} alt={item?.image.altText} />
          </Image>
        )
      })}
    </Container>
  )
}
const Container = styled.div`
  position: relative;
  height: 520px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const ContentContainer = styled(Edges)`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
`

const Headline = styled(Typography)`
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
`

const Subline = styled(Typography)`
  color: #ffffff;
  max-width: 460px;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  padding: 8px 32px;
`

const ButtonStyle = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  transition: opacity 1s ease-in-out;
  background-color: ${(props) => (props.isVisible ? "#ffffff" : "#4c656c")};
`

const Image = styled.div`
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out !important;
    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    z-index: 0;
    object-fit: cover;
    object-position: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(76, 101, 108, 0.3);
      width: 100vw;
      height: 100%;
      z-index: 1;
    }
  }
`
