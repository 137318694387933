import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"

export default function WhereToBuy({ header, companies }) {
  return (
    <Container id="wheretobuy">
      <EdgesContainer size="md">
        <Header>
          <Typography variant="h2" gutterBottom>
            {header}
          </Typography>
        </Header>
        <CompaniesContainer>
          {companies?.map((company, idx) => {
            const img = getImage(company?.image?.localFile)
            return (
              <Company key={idx}>
                <StyledLink to={company?.url} target="_blank">
                  <BoxShadow>
                    <GatsbyImage image={img} alt={company?.image?.altText || ""} />
                  </BoxShadow>
                </StyledLink>
                <CompanyInfo>
                  <h4>{company?.title}</h4>
                  <Details>
                    <StyledLink to={company?.url} target="_blank">
                      {company?.url?.replace("https://", "")}
                    </StyledLink>
                    <p>{company?.address}</p>
                    <StyledLink to={`${company?.phoneNumber?.toLowerCase()}`}>{company?.phoneNumber}</StyledLink>
                  </Details>
                </CompanyInfo>
              </Company>
            )
          })}
        </CompaniesContainer>
      </EdgesContainer>
    </Container>
  )
}

const Container = styled.div`
  background: #ffffff;
  padding: 50px 0;
  width: 100%;

  @media (min-width: 768px) {
    padding: 100px 0;
  }
`

const EdgesContainer = styled(Edges)``

const Header = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const CompaniesContainer = styled.div`
  width: 100%;
  margin-left: 0;

  @media (min-width: 768px) {
    margin-left: 250px;
  }
`

const Company = styled.div`
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
`

const StyledLink = styled(Link)`
  position: relative;
  margin-right: 20px;
  background: #ffffff;
  margin-top: 10px;
`

const CompanyInfo = styled.div``

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const BoxShadow = styled.div`
  height: 120px;
  max-height: 100%;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
  width: 260px;

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    background-position: center !important;
    object-fit: contain !important;
  }
  .gatsby-image-wrapper img {
    object-fit: contain !important;
  }
`
