import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import HeroSection from "../../../components/EngineeredWood/HeroCarousel"
import FrameModule from "../../../components/EngineeredWood/FrameModule"
import TextImage from "../../../components/EngineeredWood/TextImage"
import WhereToBuy from "../../../components/EngineeredWood/WhereToBuy"

export default function EngineeredWoodTemplate(props) {
  const {
    data: {
      wpPage: {
        templateEngineeredWood: { heroCarousel, frameModule, textImage1, textImage2, whereToBuy }
      }
    }
  } = props

  return (
    <>
      <Container>
        <HeroSection headline={heroCarousel.headline} subline={heroCarousel.subline} images={heroCarousel.images} />
        <FrameModule content={frameModule.content} headline={frameModule.headline} image={frameModule.image} />
        <TextImage alignment={textImage1.alignment} content={textImage1.content} image={textImage1.image} />
        <TextImage alignment={textImage2.alignment} content={textImage2.content} image={textImage2.image} />
        <WhereToBuy header={whereToBuy.header} companies={whereToBuy.companies} />
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 40px;
  overflow: hidden;
`

export const CollectionQuery = graphql`
  query EngineeredWoodTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSearch
      }
      templateEngineeredWood {
        fieldGroupName
        frameModule {
          content
          fieldGroupName
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        heroCarousel {
          fieldGroupName
          headline
          images {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          subline
        }
        textImage1 {
          alignment
          content
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        textImage2 {
          alignment
          content
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        whereToBuy {
          companies {
            address
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            phoneNumber
            title
            url
          }
          header
          fieldGroupName
        }
      }
    }
  }
`
